import { h, Fragment } from 'preact';
var Breadcrumb = function Breadcrumb(props) {
  var getFolder = props.getFolder,
    title = props.title,
    isLast = props.isLast;
  return h(Fragment, null, h("button", {
    type: "button",
    className: "uppy-u-reset uppy-c-btn",
    onClick: getFolder
  }, title), !isLast ? ' / ' : '');
};
export default function Breadcrumbs(props) {
  var _getFolder = props.getFolder,
    title = props.title,
    breadcrumbsIcon = props.breadcrumbsIcon,
    breadcrumbs = props.breadcrumbs;
  return h("div", {
    className: "uppy-Provider-breadcrumbs"
  }, h("div", {
    className: "uppy-Provider-breadcrumbsIcon"
  }, breadcrumbsIcon), breadcrumbs.map(function (directory, i) {
    return h(Breadcrumb, {
      key: directory.id,
      getFolder: function getFolder() {
        return _getFolder(directory.requestPath, directory.name);
      },
      title: i === 0 ? title : directory.name,
      isLast: i + 1 === breadcrumbs.length
    });
  }));
}