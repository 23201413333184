function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { cloneElement, toChildArray } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import classNames from 'classnames';
var transitionName = 'uppy-transition-slideDownUp';
var duration = 250;

/**
 * Vertical slide transition.
 *
 * This can take a _single_ child component, which _must_ accept a `className` prop.
 *
 * Currently this is specific to the `uppy-transition-slideDownUp` transition,
 * but it should be simple to extend this for any type of single-element
 * transition by setting the CSS name and duration as props.
 */
function Slide(_ref) {
  var children = _ref.children;
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    cachedChildren = _useState2[0],
    setCachedChildren = _useState2[1];
  var _useState3 = useState(''),
    _useState4 = _slicedToArray(_useState3, 2),
    className = _useState4[0],
    setClassName = _useState4[1];
  var enterTimeoutRef = useRef();
  var leaveTimeoutRef = useRef();
  var animationFrameRef = useRef();
  var handleEnterTransition = function handleEnterTransition() {
    setClassName("".concat(transitionName, "-enter"));
    cancelAnimationFrame(animationFrameRef.current);
    clearTimeout(leaveTimeoutRef.current);
    leaveTimeoutRef.current = undefined;
    animationFrameRef.current = requestAnimationFrame(function () {
      setClassName("".concat(transitionName, "-enter ").concat(transitionName, "-enter-active"));
      enterTimeoutRef.current = setTimeout(function () {
        setClassName('');
      }, duration);
    });
  };
  var handleLeaveTransition = function handleLeaveTransition() {
    setClassName("".concat(transitionName, "-leave"));
    cancelAnimationFrame(animationFrameRef.current);
    clearTimeout(enterTimeoutRef.current);
    enterTimeoutRef.current = undefined;
    animationFrameRef.current = requestAnimationFrame(function () {
      setClassName("".concat(transitionName, "-leave ").concat(transitionName, "-leave-active"));
      leaveTimeoutRef.current = setTimeout(function () {
        setCachedChildren(null);
        setClassName('');
      }, duration);
    });
  };
  useEffect(function () {
    var child = toChildArray(children)[0];
    if (cachedChildren === child) return;
    if (child && !cachedChildren) {
      handleEnterTransition();
    } else if (cachedChildren && !child && !leaveTimeoutRef.current) {
      handleLeaveTransition();
    }
    setCachedChildren(child);
  }, [children, cachedChildren]); // Dependency array to trigger effect on children change

  useEffect(function () {
    return function () {
      clearTimeout(enterTimeoutRef.current);
      clearTimeout(leaveTimeoutRef.current);
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, []); // Cleanup useEffect

  if (!cachedChildren) return null;
  return cloneElement(cachedChildren, {
    className: classNames(className, cachedChildren.props.className)
  });
}
export default Slide;