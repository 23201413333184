/* eslint-disable react/require-default-props */
import { h } from 'preact';
import classNames from 'classnames';
function GridListItem(props) {
  var className = props.className,
    isDisabled = props.isDisabled,
    restrictionError = props.restrictionError,
    isChecked = props.isChecked,
    title = props.title,
    itemIconEl = props.itemIconEl,
    showTitles = props.showTitles,
    toggleCheckbox = props.toggleCheckbox,
    recordShiftKeyPress = props.recordShiftKeyPress,
    id = props.id,
    children = props.children;
  var checkBoxClassName = classNames('uppy-u-reset', 'uppy-ProviderBrowserItem-checkbox', 'uppy-ProviderBrowserItem-checkbox--grid', {
    'uppy-ProviderBrowserItem-checkbox--is-checked': isChecked
  });
  return h("li", {
    className: className,
    title: isDisabled ? restrictionError == null ? void 0 : restrictionError.message : undefined
  }, h("input", {
    type: "checkbox",
    className: checkBoxClassName,
    onChange: toggleCheckbox,
    onKeyDown: recordShiftKeyPress
    // @ts-expect-error this is fine onMouseDown too
    ,

    onMouseDown: recordShiftKeyPress,
    name: "listitem",
    id: id,
    checked: isChecked,
    disabled: isDisabled,
    "data-uppy-super-focusable": true
  }), h("label", {
    htmlFor: id,
    "aria-label": title,
    className: "uppy-u-reset uppy-ProviderBrowserItem-inner"
  }, itemIconEl, showTitles && title, children));
}
export default GridListItem;