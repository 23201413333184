function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import getFileType from "./getFileType.js";
function encodeCharacter(character) {
  return character.charCodeAt(0).toString(32);
}
function encodeFilename(name) {
  var suffix = '';
  return name.replace(/[^A-Z0-9]/gi, function (character) {
    suffix += "-".concat(encodeCharacter(character));
    return '/';
  }) + suffix;
}

/**
 * Takes a file object and turns it into fileID, by converting file.name to lowercase,
 * removing extra characters and adding type, size and lastModified
 */
export default function generateFileID(file, instanceId) {
  // It's tempting to do `[items].filter(Boolean).join('-')` here, but that
  // is slower! simple string concatenation is fast

  var id = instanceId || 'uppy';
  if (typeof file.name === 'string') {
    id += "-".concat(encodeFilename(file.name.toLowerCase()));
  }
  if (file.type !== undefined) {
    id += "-".concat(file.type);
  }
  if (file.meta && typeof file.meta.relativePath === 'string') {
    id += "-".concat(encodeFilename(file.meta.relativePath.toLowerCase()));
  }
  if (file.data.size !== undefined) {
    id += "-".concat(file.data.size);
  }
  if (file.data.lastModified !== undefined) {
    id += "-".concat(file.data.lastModified);
  }
  return id;
}

// If the provider has a stable, unique ID, then we can use that to identify the file.
// Then we don't have to generate our own ID, and we can add the same file many times if needed (different path)
function hasFileStableId(file) {
  if (!file.isRemote || !file.remote) return false;
  // These are the providers that it seems like have stable IDs for their files. The other's I haven't checked yet.
  var stableIdProviders = new Set(['box', 'dropbox', 'drive', 'facebook', 'unsplash']);
  return stableIdProviders.has(file.remote.provider);
}
export function getSafeFileId(file, instanceId) {
  if (hasFileStableId(file)) return file.id;
  var fileType = getFileType(file);
  return generateFileID(_objectSpread(_objectSpread({}, file), {}, {
    type: fileType
  }), instanceId);
}