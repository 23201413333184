import { h } from 'preact';
import formatSeconds from "./formatSeconds.js";
export default function RecordingLength(_ref) {
  var recordingLengthSeconds = _ref.recordingLengthSeconds,
    i18n = _ref.i18n;
  var formattedRecordingLengthSeconds = formatSeconds(recordingLengthSeconds);
  return h("span", {
    "aria-label": i18n('recordingLength', {
      recording_length: formattedRecordingLengthSeconds
    })
  }, formattedRecordingLengthSeconds);
}