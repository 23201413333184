function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
/* eslint-disable react/require-default-props */
import { h } from 'preact';
import classNames from 'classnames';
import remoteFileObjToLocal from '@uppy/utils/lib/remoteFileObjToLocal';
import { useMemo } from 'preact/hooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore untyped
import VirtualList from '@uppy/utils/lib/VirtualList';
import SearchFilterInput from "./SearchFilterInput.js";
import FooterActions from "./FooterActions.js";
import Item from "./Item/index.js";
var VIRTUAL_SHARED_DIR = 'shared-with-me';
function ListItem(props) {
  var currentSelection = props.currentSelection,
    uppyFiles = props.uppyFiles,
    viewType = props.viewType,
    isChecked = props.isChecked,
    _toggleCheckbox = props.toggleCheckbox,
    recordShiftKeyPress = props.recordShiftKeyPress,
    showTitles = props.showTitles,
    i18n = props.i18n,
    validateRestrictions = props.validateRestrictions,
    getNextFolder = props.getNextFolder,
    f = props.f;
  if (f.isFolder) {
    return Item({
      showTitles: showTitles,
      viewType: viewType,
      i18n: i18n,
      id: f.id,
      title: f.name,
      getItemIcon: function getItemIcon() {
        return f.icon;
      },
      isChecked: isChecked(f),
      toggleCheckbox: function toggleCheckbox(event) {
        return _toggleCheckbox(event, f);
      },
      recordShiftKeyPress: recordShiftKeyPress,
      type: 'folder',
      // TODO: when was this supposed to be true?
      isDisabled: false,
      isCheckboxDisabled: f.id === VIRTUAL_SHARED_DIR,
      // getNextFolder always exists when f.isFolder is true
      handleFolderClick: function handleFolderClick() {
        return getNextFolder(f);
      }
    });
  }
  var restrictionError = validateRestrictions(remoteFileObjToLocal(f), [].concat(_toConsumableArray(uppyFiles), _toConsumableArray(currentSelection)));
  return Item({
    id: f.id,
    title: f.name,
    author: f.author,
    getItemIcon: function getItemIcon() {
      return viewType === 'grid' && f.thumbnail ? f.thumbnail : f.icon;
    },
    isChecked: isChecked(f),
    toggleCheckbox: function toggleCheckbox(event) {
      return _toggleCheckbox(event, f);
    },
    isCheckboxDisabled: false,
    recordShiftKeyPress: recordShiftKeyPress,
    showTitles: showTitles,
    viewType: viewType,
    i18n: i18n,
    type: 'file',
    isDisabled: Boolean(restrictionError) && !isChecked(f),
    restrictionError: restrictionError
  });
}
function Browser(props) {
  var currentSelection = props.currentSelection,
    folders = props.folders,
    files = props.files,
    uppyFiles = props.uppyFiles,
    viewType = props.viewType,
    headerComponent = props.headerComponent,
    showBreadcrumbs = props.showBreadcrumbs,
    isChecked = props.isChecked,
    toggleCheckbox = props.toggleCheckbox,
    recordShiftKeyPress = props.recordShiftKeyPress,
    handleScroll = props.handleScroll,
    showTitles = props.showTitles,
    i18n = props.i18n,
    validateRestrictions = props.validateRestrictions,
    isLoading = props.isLoading,
    showSearchFilter = props.showSearchFilter,
    search = props.search,
    searchTerm = props.searchTerm,
    clearSearch = props.clearSearch,
    searchOnInput = props.searchOnInput,
    searchInputLabel = props.searchInputLabel,
    clearSearchLabel = props.clearSearchLabel,
    getNextFolder = props.getNextFolder,
    cancel = props.cancel,
    done = props.done,
    noResultsLabel = props.noResultsLabel,
    virtualList = props.virtualList;
  var selected = currentSelection.length;
  var rows = useMemo(function () {
    return [].concat(_toConsumableArray(folders), _toConsumableArray(files));
  }, [folders, files]);
  return h("div", {
    className: classNames('uppy-ProviderBrowser', "uppy-ProviderBrowser-viewType--".concat(viewType))
  }, headerComponent && h("div", {
    className: "uppy-ProviderBrowser-header"
  }, h("div", {
    className: classNames('uppy-ProviderBrowser-headerBar', !showBreadcrumbs && 'uppy-ProviderBrowser-headerBar--simple')
  }, headerComponent)), showSearchFilter && h("div", {
    class: "uppy-ProviderBrowser-searchFilter"
  }, h(SearchFilterInput, {
    search: search,
    searchTerm: searchTerm,
    clearSearch: clearSearch,
    inputLabel: searchInputLabel,
    clearSearchLabel: clearSearchLabel,
    inputClassName: "uppy-ProviderBrowser-searchFilterInput",
    searchOnInput: searchOnInput
  })), function () {
    if (isLoading) {
      return h("div", {
        className: "uppy-Provider-loading"
      }, h("span", null, typeof isLoading === 'string' ? isLoading : i18n('loading')));
    }
    if (!folders.length && !files.length) {
      return h("div", {
        className: "uppy-Provider-empty"
      }, noResultsLabel);
    }
    if (virtualList) {
      return h("div", {
        className: "uppy-ProviderBrowser-body"
      }, h("ul", {
        className: "uppy-ProviderBrowser-list"
      }, h(VirtualList, {
        data: rows,
        renderRow: function renderRow(f) {
          return h(ListItem, {
            currentSelection: currentSelection,
            uppyFiles: uppyFiles,
            viewType: viewType,
            isChecked: isChecked,
            toggleCheckbox: toggleCheckbox,
            recordShiftKeyPress: recordShiftKeyPress,
            showTitles: showTitles,
            i18n: i18n,
            validateRestrictions: validateRestrictions,
            getNextFolder: getNextFolder,
            f: f
          });
        },
        rowHeight: 31
      })));
    }
    return h("div", {
      className: "uppy-ProviderBrowser-body"
    }, h("ul", {
      className: "uppy-ProviderBrowser-list",
      onScroll: handleScroll,
      role: "listbox"
      // making <ul> not focusable for firefox
      ,

      tabIndex: -1
    }, rows.map(function (f) {
      return h(ListItem, {
        currentSelection: currentSelection,
        uppyFiles: uppyFiles,
        viewType: viewType,
        isChecked: isChecked,
        toggleCheckbox: toggleCheckbox,
        recordShiftKeyPress: recordShiftKeyPress,
        showTitles: showTitles,
        i18n: i18n,
        validateRestrictions: validateRestrictions,
        getNextFolder: getNextFolder,
        f: f
      });
    })));
  }(), selected > 0 && h(FooterActions, {
    selected: selected,
    done: done,
    cancel: cancel,
    i18n: i18n
  }));
}
export default Browser;