import { h } from 'preact';
export default function PermissionsScreen(_ref) {
  var icon = _ref.icon,
    i18n = _ref.i18n,
    hasCamera = _ref.hasCamera;
  return h("div", {
    className: "uppy-Webcam-permissons"
  }, h("div", {
    className: "uppy-Webcam-permissonsIcon"
  }, icon()), h("h1", {
    className: "uppy-Webcam-title"
  }, hasCamera ? i18n('allowAccessTitle') : i18n('noCameraTitle')), h("p", null, hasCamera ? i18n('allowAccessDescription') : i18n('noCameraDescription')));
}