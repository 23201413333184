function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import isDOMElement from "./isDOMElement.js";
/**
 * Find one or more DOM elements.
 */
function findAllDOMElements(element) {
  if (typeof element === 'string') {
    var elements = document.querySelectorAll(element);
    return elements.length === 0 ? null : Array.from(elements);
  }
  if (_typeof(element) === 'object' && isDOMElement(element)) {
    return [element];
  }
  return null;
}
export default findAllDOMElements;