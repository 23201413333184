// See this cropperjs image to understand how container/image/canavas/cropbox relate to each other.
// (https://github.com/fengyuanchen/cropperjs/blob/9b528a8baeaae876dc090085e37992a1683c6f34/docs/images/layers.jpg)
//
function getCanvasDataThatFitsPerfectlyIntoContainer(containerData, canvasData) {
  // 1. Scale our canvas as much as possible
  var widthRatio = containerData.width / canvasData.width;
  var heightRatio = containerData.height / canvasData.height;
  var scaleFactor = Math.min(widthRatio, heightRatio);
  var newWidth = canvasData.width * scaleFactor;
  var newHeight = canvasData.height * scaleFactor;

  // 2. Center our canvas
  var newLeft = (containerData.width - newWidth) / 2;
  var newTop = (containerData.height - newHeight) / 2;
  return {
    width: newWidth,
    height: newHeight,
    left: newLeft,
    top: newTop
  };
}
export default getCanvasDataThatFitsPerfectlyIntoContainer;